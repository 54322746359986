import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'

import LegalEyeLogo from '../assets/images/logos/legaleye-logo-dark.svg'
import LawAdvisorVenturesLogo from '../assets/images/logos/lawadvisor-ventures-logo-white.svg'
import MenuIcon from '../assets/images/icons/mobile-icon-menu.svg'
import CloseIcon from '../assets/images/icons/mobile-icon-close.svg'

const Header = ({ showBookADemoModal }) => {
  const [collapsed, setCollapsed] = useState(true)
  const { pathname } = useLocation()
  const path = pathname

  return (
    <header className="fixed top-0 left-0 right-0 z-40">
      <div className="h-8 lg:h-10 bg-black lg:container mx-auto flex justify-center items-center">
        <a href="https://lawadvisor.ventures" className="h-4">
          <img src={LawAdvisorVenturesLogo} alt="LegalEye logo" />
        </a>
      </div>
      <div className="hidden lg:flex justify-between p-8 shadow-xs container mx-auto rounded-b-lg bg-white">
        <Link className="flex-1" to="/">
          <img className="h-10" src={LegalEyeLogo} alt="LegalEye logo" />
        </Link>
        <div className="hidden lg:flex items-center">
          <Link
            to="/"
            className={`font-GTWalsheimPro font-medium px-4 mx-4  ${
              path === '/' && 'text-leg-red'
            }`}
          >
            Home
          </Link>
          <Link
            to="/learn"
            className={`font-GTWalsheimPro font-medium px-4 mx-4  ${
              path === '/learn' && 'text-leg-red'
            }`}
          >
            Learn
          </Link>
          <Link
            to="/about"
            className={`font-GTWalsheimPro font-medium px-4 mx-4  ${
              path === '/about' && 'text-leg-red'
            }`}
          >
            About
          </Link>
          <Link
            to="/news"
            className={`font-GTWalsheimPro font-medium px-4 mx-4  ${
              path === '/news' && 'text-leg-red'
            }`}
          >
            News
          </Link>
        </div>
        <div className="flex flex-1 items-center justify-end">
          <div className="hidden font-GTWalsheimPro font-medium mx-1 rounded-full px-6 py-2 border border-black">
            Contact Us
          </div>
          <button
            className="font-GTWalsheimPro font-medium mx-1 rounded-full px-6 py-2 bg-leg-red text-white"
            onClick={showBookADemoModal}
          >
            Book a Demo
          </button>
        </div>
      </div>
      <div className="px-6 py-4 shadow-xs mx-auto rounded-none lg:hidden bg-white">
        <div className="flex justify-between">
          <Link to="/">
            <img className="h-10" src={LegalEyeLogo} alt="LegalEye logo" />
          </Link>
          {collapsed
            ? (
              <button
                className="flex items-center"
                onClick={() => setCollapsed(false)}
              >
                <div className="font-GTWalsheimPro text-xl">Menu</div>
                <img src={MenuIcon} className="ml-4" />
              </button>
              )
            : (
              <button
                className="flex items-center"
                onClick={() => setCollapsed(true)}
              >
                <div className="font-GTWalsheimPro text-xl">Close</div>
                <img src={CloseIcon} className="ml-4" />
              </button>
              )
          }
        </div>
        <div
          className={`overflow-hidden ${
            collapsed ? 'max-h-0' : 'max-h-screen'
          } transition-all duration-300`}
        >
          <div className="flex flex-col mt-4">
            <Link
              to="/"
              className={`font-GTWalsheimPro font-medium py-4  ${
                path === '/' && 'text-leg-red'
              }`}
            >
              Home
            </Link>
            <Link
              to="/learn"
              className={`font-GTWalsheimPro font-medium py-4  ${
                path === '/learn' && 'text-leg-red'
              }`}
            >
              Learn
            </Link>
            <Link
              to="/about"
              className={`font-GTWalsheimPro font-medium py-4  ${
                path === '/about' && 'text-leg-red'
              }`}
            >
              About
            </Link>
            <Link
              to="/news"
              className={`font-GTWalsheimPro font-medium py-4  ${
                path === '/news' && 'text-leg-red'
              }`}
            >
              News
            </Link>
            <div className="flex items-center my-4">
              <div className="hidden font-GTWalsheimPro font-medium mr-2 rounded-full px-6 py-2 border border-black">
                Contact Us
              </div>
              <button
                className="font-GTWalsheimPro font-medium rounded-full px-6 py-2 bg-leg-red text-white"
                onClick={showBookADemoModal}
              >
                Book a Demo
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  path: PropTypes.string,
  showBookADemoModal: PropTypes.func
}

export default Header
