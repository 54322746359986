export const submitForm = (params) => {
  const postUrl = 'https://api.fibonacci.legal/PartnersFormHandler'
  const body = Object.assign(params, { product: 'LegalEye' })

  return fetch(postUrl, {
    method: 'post',
    body: JSON.stringify(body),
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  })
}

export default null
