import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { CountryDropdown } from 'react-country-region-selector'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { submitForm } from '../utils/api'

import LegalEyeLogo from '../assets/images/logos/legaleye-logo-dark.svg'

const validateEmail = (string) => {
  if (!string) return false
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape
  return re.test(String(string).toLowerCase())
}

const BookADemoModal = ({ onClose }) => {
  const [submitted, setSubmitted] = useState(null)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [title, setTitle] = useState('')
  const [country, setCountry] = useState('')

  const submitApplicationAction = async (e) => {
    setSubmitted(null)
    e.preventDefault()
    const params = {
      email: email,
      full_name: name,
      jobtitle: title,
      country: country,
      partnership_type: 'Demo Request'
    }
    if (validateEmail(email)) {
      const token = await executeRecaptcha('legaleyeDemoForm')

      submitForm({ ...params, token })
        .then((res) => {
          if (res.ok) {
            setSubmitted(true)
          } else {
            setSubmitted(false)
          }
        })
        .catch(() => setSubmitted(false))
    } else {
      setSubmitted(false)
    }
  }

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-white z-[100] overflow-y-auto">
      <div className="flex justify-between mt-5 p-8 container mx-auto">
        <a href="/">
          <img className="h-10" src={LegalEyeLogo} alt="LegalEye logo" />
        </a>
        <button
          className="font-GTWalsheimPro font-medium mx-1 rounded-full px-6 py-2 border border-black"
          onClick={onClose}
        >
          Close
        </button>
      </div>
      {submitted ? (
        <div className="container flex-col flex mt-4 md:mt-10 px-8 mx-auto items-start">
          <h1 className="text-3xl lg:text-6xl  lg:leading-tight font-GTWalsheimPro font-medium">
            Thank you! <br />
            We received it and will get <br />
            back to you in no time.
          </h1>
          <button
            className="font-GTWalsheimPro font-medium mx-1 rounded-full px-6 py-2 border border-black mt-10"
            onClick={onClose}
          >
            Go back home
          </button>
        </div>
      ) : (
        <div className="container flex-col md:flex-row flex mt-4 md:mt-10 pb-10 px-8 mx-auto">
          <div className="flex-1 mr-5">
            <h1 className="text-3xl lg:text-6xl  lg:leading-tight font-GTWalsheimPro font-medium">
              Request a Demo
            </h1>
            <p className="text-xl lg:text-2xl lg:leading-10 font-GTWalsheimPro mt-6 text-[#727272]">
              Since LegalEye&apos;s launch in early 2021, it has become the trusted
              external counsel management platform for the world&apos;s leading
              financial institutions and pioneering corporate legal teams.
              Similarly, over 100 law firms have been onboarded onto LegalEye
              and are now servicing their clients more collaboratively, with
              better visibility and increased transparency and cost-control that
              is expected from today&apos;s legal service providers.
            </p>
            <p className="text-xl lg:text-2xl lg:leading-10 font-GTWalsheimPro mt-6 text-[#727272]">
              Interested in modernising your corporate legal department or law
              firm? Organise a demo and a discussion with our product
              specialists to help you understand how LegalEye can help transform
              your legal operations.
            </p>
          </div>
          <div className="flex-1 mt-5 md:mt-8 md:ml-5">
            <form className="font-GTWalsheimPro flex flex-col">
              <div className="">
                <label className="font-medium" htmlFor="exampleInputEmail1">
                  Full name
                </label>
                <input
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                  type="text"
                  className="w-full p-4 rounded border-[#E9E9E9] mt-2"
                  placeholder="What’s your full name?"
                />
              </div>
              <div className="mt-6">
                <label className="font-medium" htmlFor="exampleInputEmail1">
                  Email
                </label>
                <input
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                  type="email"
                  className="w-full p-4 rounded border-[#E9E9E9] mt-2"
                  placeholder="What’s your work email?"
                />
              </div>
              <div className="mt-6">
                <label className="font-medium" htmlFor="exampleInputEmail1">
                  Job Title
                </label>
                <input
                  onChange={(e) => {
                    setTitle(e.target.value)
                  }}
                  type="text"
                  className="w-full p-4 rounded border-[#E9E9E9] mt-2"
                  placeholder="And your role title?"
                />
              </div>
              <div className="mt-6">
                <label className="font-medium" htmlFor="exampleInputEmail1">
                  Country
                </label>
                <CountryDropdown
                  className="w-full p-4 rounded border-[#E9E9E9] mt-2"
                  onChange={(country) => {
                    setCountry(country)
                  }}
                  value={country}
                  priorityOptions={['CA', 'US', 'GB', 'SG', 'AU']}
                />
              </div>
              <div className="text-xs mt-4">
                This site is protected by reCAPTCHA and the Google <a className="underline" href="https://policies.google.com/privacy">Privacy Policy</a> and <a className="underline" href="https://policies.google.com/terms">Terms of Service</a> apply.
              </div>
              {submitted === false && (
                <div className="text-[#DC3545] mt-4">
                  Something went wrong. Please try again
                </div>
              )}
              <button
                className="font-GTWalsheimPro font-medium mx-1 rounded-full px-6 py-3 bg-leg-red text-white self-end mt-8"
                onClick={submitApplicationAction}
              >
                Book a Demo
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

BookADemoModal.propTypes = {
  onClose: PropTypes.func
}

export default BookADemoModal
