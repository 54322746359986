import React from 'react'
import { Link } from 'gatsby'

import LegalEyeLogo from '../assets/images/logos/legaleye-logo-dark.svg'
import LawAdvisorVenturesLogo from '../assets/images/logos/lawadvisor-ventures-logo-white.svg'
import { deleteAllCookies } from '../utils/cookies'

const Footer = () => {
  return (
    <footer>
      <div className="block bg-alt-bg">
        <div className="default-container lg:py-16 flex flex-col lg:flex-row justify-between">
          <Link to="/">
            <img src={LegalEyeLogo} />
          </Link>
          <div className="flex flex-col lg:flex-row font-GTWalsheimPro mt-6 lg:mt-0">
            <div className="flex flex-col items-start">
              <div className="text-xs">CONTENT</div>
              <Link className="font-medium mt-4" to="/">
                Home
              </Link>
              <Link className="font-medium mt-4" to="/learn">
                Learn
              </Link>
              <Link className="font-medium mt-4" to="/about">
                About
              </Link>
              <Link className="font-medium mt-4" to="/news">
                News
              </Link>
            </div>
            <div className="flex flex-col items-start mt-6 lg:mt-0 lg:ml-20">
              <div className="text-xs">LEGAL</div>
              <Link className="font-medium mt-4" to="/terms-of-service">
                Terms of Service
              </Link>
              <Link className="font-medium mt-4" to="/privacy-policy">
                Privacy Policy
              </Link>
              <Link className="font-medium mt-4" to="/sub-processors">
                Subprocessors List
              </Link>
            </div>
            <div className="flex flex-col items-start mt-6 lg:mt-0 lg:ml-20">
              <div className="text-xs">OTHER</div>
              <button
                style={{
                  padding: 0,
                  textAlign: 'left',
                  border: 'none',
                  backgroundColor: 'transparent',
                  lineHeight: '22.22px',
                  fontWeight: 500
                }}
                className="mt-4"
                onClick={() => {
                  deleteAllCookies()

                  location.reload()
                }}
              >
                Manage your cookies
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-main-black text-white">
        <div className="default-container lg:py-6 flex flex-col-reverse lg:flex-row justify-between">
          <div className="mt-4 lg:mt-0">
            © Copyright {new Date().getFullYear()} • LawAdvisor Ventures Ltd.
            <br />
            All rights reserved.
          </div>
          <div className="flex items-center">
            <div>Powered by</div>
            <a href="https://lawadvisor.ventures" className="ml-3">
              <img
                className="h-6"
                src={LawAdvisorVenturesLogo}
                alt="LegalEye logo"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
