import React, { useState, useEffect } from 'react'

import { Link } from 'gatsby'
import ReactCookieConsent, { Cookies } from 'react-cookie-consent'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

import { deleteAllCookies } from '../utils/cookies'

const CookieConsent = function () {
  const location = useLocation()
  const [showMore, setShowMore] = useState(true)

  useEffect(() => {
    const resetCookie = Cookies.get('reset-cookie')

    if (resetCookie == null) {
      deleteAllCookies()
      Cookies.set('reset-cookie', new Date().toISOString(), { expires: 99999 })
    }

    if (
      window.innerWidth <= 375 ||
      (window.innerWidth <= 600 && window.innerHeight <= 900)
    ) {
      setShowMore(false)
    }
  }, [])

  return (
    <ReactCookieConsent
      location="bottom"
      style={{
        background: '#1B171B',
        borderRadius: '4px',
        maxWidth: '400px',
        minWidth: '200px',
        width: 'calc(100% - 32px)',
        left: 'unset',
        right: '16px',
        marginBottom: '16px'
      }}
      buttonWrapperClasses="consent-buttons"
      contentStyle={{ flex: '1 0 200px', marginBottom: 0 }}
      buttonStyle={{
        borderRadius: 4,
        fontSize: '14px',
        background: 'transparent',
        border: '1px solid white',
        color: 'white'
      }}
      declineButtonStyle={{
        borderRadius: '4px',
        fontSize: '14px',
        background: 'transparent'
      }}
      buttonText="I understand"
      declineButtonText="Decline"
      enableDeclineButton={true}
      cookieName="gatsby-gdpr-google-analytics"
      onAccept={() => {
        initializeAndTrack(location)
      }}
    >
      {showMore
        ? (
        <span style={{ fontSize: '14px' }}>
          By selecting &apos;I Understand&apos;, you allow LawAdvisor to use
          analytical cookies. We use analytical cookies to track your activity
          for performance and analytics purposes. This allows us to test the
          effectiveness of our website and allows us to develop new designs to
          improve your browsing experience.
          <br />
          <br />
          Please visit our{' '}
          <Link
            to="/privacy-policy"
            style={{ color: 'white', textDecoration: 'underline' }}
          >
            Privacy Policy
          </Link>{' '}
          (see Cookies section) for more information and to understand how we
          use your data for required purposes.
        </span>
          )
        : (
        <span style={{ fontSize: '14px' }}>
          By selecting &apos;I Understand&apos;, you allow LawAdvisor to use
          analytical cookies.
          <br />
          <button
            style={{
              color: 'white',
              padding: 0,
              textDecoration: 'underline',
              border: 'none',
              backgroundColor: 'transparent'
            }}
            onClick={() => setShowMore(true)}
          >
            See more...
          </button>
        </span>
          )}
    </ReactCookieConsent>
  )
}

export default CookieConsent
