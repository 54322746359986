import { Cookies } from 'react-cookie-consent'

const ignoreCookies = 'reset-cookie'
const knownCookies = 'gatsby-gdpr-google-analytics'

export const deleteAllCookies = () => {
  const cookies = Cookies.get()

  if (typeof cookies === 'object' && cookies !== null) {
    const keys = Object.keys(cookies)

    for (let i = 0; i < keys.length; i += 1) {
      const ignore = ignoreCookies.indexOf(keys[i]) > -1
      const known = knownCookies.indexOf(keys[i]) > -1

      if (ignore) continue

      if (known) {
        Cookies.remove(keys[i])
      } else {
        const dotdomain = `.${document.location.hostname}`

        Cookies.remove(keys[i], { domain: dotdomain })
      }
    }
  }
}

export default null
