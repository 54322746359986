import React, { useState } from 'react'
import { useLocation } from '@reach/router'

import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import Header from './header'
import Footer from './footer'
import BookADemoModal from './demo-modal'
import CookieConsent from './cookie-consent'
import { GOOGLE_RECAPTCHA_KEY } from '../utils/variables'

const metaImg = '/metaimages/legaleye-metaimage.jpg'

function Layout ({ children, title, image, description }) {
  const [showModal, setShowModal] = useState(false)
  const showBookADemoModal = () => setShowModal(true)
  const hideBookADemoModal = () => setShowModal(false)

  const { pathname } = useLocation()
  const path = pathname

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
        <title>{title || 'LegalEye'}</title>
        <meta name="description" content={description || 'Powering smarter relationships'} />
        <meta name="image" content={image || metaImg} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://www.legaleye.com${path}`} />
        <meta property="og:title" content={title || 'LegalEye'} />
        <meta property="og:description" content={description || 'Powering smarter relationships'} />
        <meta property="og:image" content={image || metaImg} />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
      </Helmet>
      <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_KEY}>
        <div className="flex flex-col font-sans text-gray-900">
          <Header showBookADemoModal={showBookADemoModal} />
          <main>
            <div className="mt-[104px] lg:mt-0 h-full">{children}</div>
          </main>
          <Footer />
          <CookieConsent />
        </div>
        {showModal && <BookADemoModal onClose={hideBookADemoModal} />}
      </GoogleReCaptchaProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
